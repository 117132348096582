import ruMessages from "../messages/ru_RU.json";
import ruRU from "antd/es/locale/ru_RU";

const ru_RU = {
  messages: {
    ...ruMessages,
  },
  antd: ruRU,
  locale: "ru-RU",
};

export default ru_RU;
