/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import IndexRouter from "./routes";
import { zhCN, enUS, ruRU } from "@mui/material/locale";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { changeLocale } from "./redux/changeLocale/Locale";
import request from "./api/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
  const curLocale = useSelector((state: RootState) => state.localReducer.Lan);
  const darkTheme = createTheme(
    {
      typography: {
        fontSize: 16,
      },
      palette: {
        mode: "dark",
        background: {},
      },
    },
    zhCN,
  );

  const [theme, setTheme] = useState(darkTheme);
  interface Response {
    data: [{ key: string; value: string }];
    code: number;
  }
  const header: unknown = {
    "Content-Type": "multipart/form-data",
  };
  function getConfigLocal() {
    return new Promise(function (resolve, reject) {
      request("get", "config", "", header, { key: "default-lang" })
        .then(function (res) {
          const rep: Response = res.data as Response;
          if (rep.code === 0) {
            resolve(rep.data[0].value);
          } else {
            resolve("en"); // or you can reject with an error message if you want
          }
        })
        .catch(function (err) {
          console.log("get default-lang err", err);
          reject(err);
        });
    });
  }
  const dispatch = useDispatch();
  const mapDispatchToProps = {
    changeLocal: (payload: "zh_CN" | "en_US" | "ru_RU") => dispatch(changeLocale(payload)),
  };

  useEffect(() => {
    // updateLogo()
    getConfigLocal()
      .then(function (value) {
        if (localStorage.getItem("Local")) {
          mapDispatchToProps.changeLocal(
            localStorage.getItem("Local") === "zh_CN"
              ? "zh_CN"
              : localStorage.getItem("Local") === "ru_RU"
                ? "ru_RU"
                : "en_US",
          );
        } else {
          mapDispatchToProps.changeLocal(value === "zh" ? "zh_CN" : value === "ru" ? "ru_RU" : "en_US");
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(function () {});
  }, []);
  useEffect(() => {
    // 在 count 状态发生变化时执行操作
    if (curLocale === "zh_CN") {
      const ThemeZh = createTheme(
        {
          typography: {
            fontSize: 16,
          },
          palette: {
            mode: "dark",
            background: {},
          },
        },
        zhCN,
      );
      setTheme(ThemeZh);
    } else if (curLocale === "en_US") {
      const ThemeEn = createTheme(
        {
          typography: {
            fontSize: 16,
          },
          palette: {
            mode: "dark",
            background: {},
          },
        },
        enUS,
      );
      setTheme(ThemeEn);
    } else if (curLocale === "ru_RU") {
      const ThemeEn = createTheme(
        {
          typography: {
            fontSize: 16,
          },
          palette: {
            mode: "dark",
            background: {},
          },
        },
        ruRU,
      );
      setTheme(ThemeEn);
    }
  }, [curLocale]);

  return (
    <ThemeProvider theme={theme}>
      <IndexRouter />
    </ThemeProvider>
  );
};

export default App;
