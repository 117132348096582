/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from "../views/Login/Login";
import { IntlProvider } from "react-intl";
import { RootState } from "../redux/store";
import { connect } from "react-redux";
import * as Redux from "redux";
import { ConfigProvider } from "antd";
import request, { getStaticUrl } from "../api/request";
import { changeConfigTitle } from "../redux/changeConfigTitle/configTitle";
import "moment/locale/zh-cn";

interface Response {
  data: [{ key: string; value: string }];
  code: number;
}

const Login = lazy(() => import("../views/Login/Login"));
const Home = lazy(() => import("../views/Home/Home"));
const PersonalCenter = lazy(() => import("../views/personalCenter/personalCenter"));

class IndexRouter extends React.Component<any, { ccsTitle: string }> {
  constructor(props: any) {
    super(props);
    this.state = {
      ccsTitle: "", // Default value
    };
  }

  private getConfigLocalKey(value: any) {
    if (localStorage.getItem("Local")) {
      return localStorage.getItem("Local") === "zh_CN" ? "zh" : localStorage.getItem("Local") === "ru_RU" ? "ru" : "en";
    } else {
      return value;
    }
  }

  private getConfigLocal() {
    return new Promise(function (resolve, reject) {
      const header: any = {
        "Content-Type": "multipart/form-data",
      };
      request("get", "config", "", header, { key: "default-lang" })
        .then(function (res) {
          const rep: Response = res.data as Response;
          if (rep.code === 0) {
            resolve(rep.data[0].value);
          } else {
            resolve("en");
          }
        })
        .catch(function (err) {
          console.log("get default-lang err", err);
          reject(err);
        });
    })
      .then(value => {
        return new Promise((resolve, reject) => {
          const header: any = {
            "Content-Type": "multipart/form-data",
          };
          request("get", "config", "", header, {
            key:
              this.getConfigLocalKey(value) === "zh"
                ? "title_zh"
                : this.getConfigLocalKey(value) === "ru"
                  ? "title_ru"
                  : "title_en",
          })
            .then(function (res) {
              const rep: Response = res.data as Response;
              if (rep.code === 0) {
                resolve(rep.data[0].value);
              } else {
                resolve("Command and Control System");
              }
            })
            .catch(function (err) {
              console.log("get default-lang err", err);
              reject(err);
            });
        });
      })
      .then(value => {
        this.props.changeConfigTitle(value);
        this.setState({
          ccsTitle: value as string,
        });
      });
  }

  private updateConfigLocal() {
    return new Promise((resolve, reject) => {
      const header: any = {
        "Content-Type": "multipart/form-data",
      };
      request("get", "config", "", header, {
        key:
          this.props.curLocale.locale === "zh-Hans-CN"
            ? "title_zh"
            : this.props.curLocale.locale === "ru-RU"
              ? "title_ru"
              : "title_en",
      })
        .then(function (res) {
          const rep: Response = res.data as Response;
          if (rep.code === 0) {
            resolve(rep.data[0].value);
          } else {
            resolve("en");
          }
        })
        .catch(function (err) {
          console.log("get default-lang err", err);
          reject(err);
        });
    }).then(value => {
      this.props.changeConfigTitle(value);
      this.setState({
        ccsTitle: value as string,
      });
    });
  }

  componentDidMount(): void {
    this.getConfigLocal();
  }

  async componentDidUpdate(prevProps: Readonly<any>) {
    if (prevProps.curLocale.locale !== this.props.curLocale.locale) {
      await this.updateConfigLocal();
    }
  }

  render() {
    const Routerr = Router as any;
    const Swtichh = Switch as any;
    const RoutJsx = Route as any;

    return (
      <ConfigProvider locale={this.props.curLocale.antd}>
        <IntlProvider locale={this.props.curLocale.locale} messages={this.props.curLocale.messages}>
          <Routerr>
            <Suspense fallback={<p>Loading...</p>}>
              <Swtichh>
                <RoutJsx
                  path="/personalCenter"
                  render={(router: any) => {
                    document.title = this.state.ccsTitle;
                    return <PersonalCenter {...router} />;
                  }}
                />
                <RoutJsx
                  path="/dashboard"
                  render={(router: any) => {
                    document.title = this.state.ccsTitle;
                    return <Home {...router} />;
                  }}
                />
                <RoutJsx
                  path="/"
                  render={() => {
                    document.title = this.state.ccsTitle;
                    return <Login />;
                  }}
                />
              </Swtichh>
            </Suspense>
          </Routerr>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    Lan: state.localReducer.Lan,
    curLocale: state.localReducer.curLocal,
  };
};
const mapDispatchToProps = (dispatch: Redux.Dispatch) => ({
  changeConfigTitle(payload: string) {
    dispatch(changeConfigTitle(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexRouter);
