import enMessages from "../messages/en.json";
import enUS from "antd/es/locale/en_US";

const EnLang = {
  messages: {
    ...enMessages,
  },
  antd: enUS,
  locale: "en-US",
};

export default EnLang;
