import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface alertShow {
  alret: boolean;
  alretAllShow: boolean;
}

const initialState: alertShow = {
  alret: false,
  alretAllShow: false,
};

export const alerMessagetShow = createSlice({
  name: "alerMessagetShow",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    alertContent: (state: alertShow, action: PayloadAction<boolean>) => {
      state.alret = action.payload;
    },
    alertAllShow: (state: alertShow, action: PayloadAction<boolean>) => {
      state.alretAllShow = action.payload;
    },
  },
});

export const { alertContent, alertAllShow } = alerMessagetShow.actions;

export default alerMessagetShow.reducer;
