import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface messageInfo {
  type: string;
  subType: string;
  action: string;
  status: string;
}

interface initInfo {
  msgQueue: messageInfo[];
  response: messageInfo[];
}

const initialState: initInfo = {
  msgQueue: [],
  response: [],
};

export const messageNotify = createSlice({
  name: "messageNotify",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    handleResponse: (state: initInfo, action: PayloadAction<messageInfo>) => {
      state.response.unshift(action.payload);
    },
    handleResponseStatus: (state: initInfo, action: PayloadAction<messageInfo>) => {
      state.response.unshift(action.payload);
    },
  },
});

export const { handleResponse, handleResponseStatus } = messageNotify.actions;

export default messageNotify.reducer;
