import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SiteInfo } from "../../types";

export interface curSiteInfo {
  curSiteInfo: SiteInfo[];
  curTabKey: number;
  newSiteInfo: { [key: string]: SiteInfo };
}

const initialState: curSiteInfo = {
  curSiteInfo: [],
  curTabKey: 0,
  newSiteInfo: {},
};

export const curSiteInfoSlice = createSlice({
  name: "curSiteInfo",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateSite: (state: curSiteInfo, action: PayloadAction<SiteInfo>) => {
      state.newSiteInfo[action.payload.site_id] = action.payload;
      // let needAdd = true;
      // state.curSiteInfo.forEach((element, index) => {
      //   if (action.payload.site_id === element.site_id) {
      //     state.curSiteInfo[index] = action.payload;
      //     needAdd = false;
      //   }
      // });
      // if (needAdd) {
      //   state.curSiteInfo.push(action.payload);
      // }
    },
    updateSiteSwitch: (
      state: curSiteInfo,
      action: PayloadAction<{ siteID: string; actionType: string; status?: boolean }>,
    ) => {
      const { actionType, siteID, status } = action.payload;
      const site = state.newSiteInfo[siteID];
      if (site) {
        if (actionType === "wide_band_attack") {
          site.jammerState = site.jammerState.map(jammer => ({
            ...jammer,
            state: status ? 2 : 1,
          }));
        } else if (actionType === "auto_attack") {
          site.enable_auto_attack = !site.enable_auto_attack;
        }
      } else {
        console.error(`Site with ID ${siteID} not found`);
      }
    },
    removeSiteById: (state: curSiteInfo, action: PayloadAction<SiteInfo>) => {
      state.curSiteInfo.forEach((element, index) => {
        if (action.payload.site_id === element.site_id) {
          state.curSiteInfo.splice(index, 1);
        }
      });
    },
    //删除多个离线站点
    removeSiteByIds: (state: curSiteInfo, action: PayloadAction<string[]>) => {
      const idsToRemove = action.payload;
      state.curSiteInfo = state.curSiteInfo.filter(elemnt => !idsToRemove.includes(elemnt.site_id));
    },
    initSiteInfo: (state: curSiteInfo, action: PayloadAction<SiteInfo[]>) => {
      state.curSiteInfo = action.payload;
    },
    setCurTabKey: (state: curSiteInfo, action: PayloadAction<number>) => {
      state.curTabKey = action.payload;
    },
    updateSiteInfo: (state: curSiteInfo, action: PayloadAction<{ [key: string]: SiteInfo }>) => {
      // const updatedData = { ...state.newSiteInfo };
      Object.keys(action.payload).forEach(key => {
        if (action.payload[key] === null) {
          delete state.newSiteInfo[key];
        } else {
          state.newSiteInfo[key] = action.payload[key];
        }
      });
    },
  },
});

export const {
  updateSite,
  updateSiteInfo,
  initSiteInfo,
  removeSiteById,
  setCurTabKey,
  removeSiteByIds,
  updateSiteSwitch,
} = curSiteInfoSlice.actions;

export default curSiteInfoSlice.reducer;
