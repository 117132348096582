import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EventsType {
  show: boolean;
}

const initialState: EventsType = {
  show: false,
};

export const eventsSetting = createSlice({
  name: "eventsSetting",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    enevtsSwitch: (state: EventsType, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { enevtsSwitch } = eventsSetting.actions;

export default eventsSetting.reducer;
