import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapboxgl from "mapbox-gl";

interface globalMapInfo {
  map: mapboxgl.Map | null;
}

const initialState: globalMapInfo = {
  map: null,
};

export const globalMap = createSlice({
  name: "globalMap",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    initMapInfo: (state: globalMapInfo, action: PayloadAction<mapboxgl.Map>) => {
      state.map = action.payload;
    },
  },
});

export const { initMapInfo } = globalMap.actions;

export default globalMap.reducer;
