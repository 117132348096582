import zhLan from "./entire/zh-CN";
import enLan from "./entire/en-US";
import ruLan from "./entire/ru_RU";

const AppLocal = {
  zh_CN: zhLan,
  en_US: enLan,
  ru_RU: ruLan,
};

export default AppLocal;
