import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface informationType {
  localName: string;
  loaclRoles: string[];
}

const initialState: informationType = {
  localName: "",
  loaclRoles: [],
};

export const loginInformation = createSlice({
  name: "loginInformation",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateLoginInformation: (state, action: PayloadAction<informationType>) => {
      state.localName = action.payload.localName;
      state.loaclRoles = action.payload.loaclRoles;
    },
  },
});

export const { updateLoginInformation } = loginInformation.actions;

export default loginInformation.reducer;
