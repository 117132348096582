import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import "./styles/global.scss";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from "./redux/store";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "antd/dist/antd.css";

const Root = () => {
  return (
    <Provider store={store}>
      {/* 使用 ConfigProvider 来设置本地化 */}
      <CssBaseline></CssBaseline>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

reportWebVitals();
