/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SiteStatus {
  siteStatus: string;
}

const initialState: SiteStatus = {
  siteStatus: "all",
};

export const changeShowSite = createSlice({
  name: "selectShowSite",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeSiteStatus: (state: SiteStatus, action: PayloadAction<string>) => {
      state.siteStatus = action.payload;
    },
  },
});

export const { changeSiteStatus } = changeShowSite.actions;

export default changeShowSite.reducer;
