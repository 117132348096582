import localReducer from "./changeLocale/Locale";
import curSiteReducer from "./objectList/siteInfo";
import curDroneReducer from "./objectList/droneInfo";
import eventsList from "./objectList/eventsList";
import globalMap from "./map/mapStore";
import messageInfo from "./message/msgNotify";
import globalSetting from "./system/setting";
import alerMessagetShow from "./alert/alert";
import videoReducer from "./video/video";
import loginInformationReducer from "./loginInformation/loginInformation";
import configTitleReducer from "./changeConfigTitle/configTitle";
import changeShowSite from "./objectList/siteFilter";
import droneStateReducer from "./objectList/droneTrace";
import droneNavReducer from "./objectList/droneNav";

export default {
  localReducer,
  curSiteReducer,
  curDroneReducer,
  eventsList,
  globalMap,
  messageInfo,
  alerMessagetShow,
  globalSetting,
  videoReducer,
  loginInformationReducer,
  configTitleReducer,
  changeShowSite,
  droneStateReducer,
  droneNavReducer,
};
