import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface configTitleState {
  configTitle: string;
}

const initialState: configTitleState = {
  configTitle: "Command and Control System",
};

export const configTitleSlice = createSlice({
  name: "titleConfig",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeConfigTitle: (state: configTitleState, action: PayloadAction<string>) => {
      state.configTitle = action.payload;
    },
  },
});

export const { changeConfigTitle } = configTitleSlice.actions;

export default configTitleSlice.reducer;
