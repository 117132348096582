import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface videoInfoType {
  url: string;
  curImage: string;
  loading: boolean;
}

const initialState: videoInfoType = {
  url: "",
  curImage: "",
  loading: false,
};

export const videoInfo = createSlice({
  name: "videoInfo",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    getVideoUrl: (state: videoInfoType, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    removeVideoUrl: (state: videoInfoType) => {
      state.url = "";
    },
    setCurImage: (state: videoInfoType, action: PayloadAction<string>) => {
      if (state.loading) {
        state.curImage = action.payload;
      }
    },
    setImageLoading: (state: videoInfoType, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { getVideoUrl, removeVideoUrl, setCurImage, setImageLoading } = videoInfo.actions;

export default videoInfo.reducer;
