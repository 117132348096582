/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AppLocal from "../../lang";

export interface LocaleState {
  curLocal: any;
  Lan: any;
}

const initLocale = localStorage.getItem("Local") ?? "en_US";

const initialState: LocaleState = {
  // curLocal:AppLocal["en_US"],
  curLocal: (AppLocal as any)[initLocale],
  Lan: initLocale,
};

export const localeSlice = createSlice({
  name: "Locale",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeLocale: (state: LocaleState, action: PayloadAction<"zh_CN" | "en_US" | "ru_RU">) => {
      state.curLocal = AppLocal[action.payload];
      state.Lan = action.payload;
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export default localeSlice.reducer;
