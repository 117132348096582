import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface droneStateType {
  showTraceList: { [key: string]: boolean };
  showTracePoints: { [key: string]: { points: number[][]; site: string } };
}

const initialState: droneStateType = {
  showTraceList: {},
  showTracePoints: {},
};

export const droneState = createSlice({
  name: "eventsSetting",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    traceOpen: (state: droneStateType, action: PayloadAction<string>) => {
      state.showTraceList[action.payload] = true;
      state.showTracePoints[action.payload] = { points: [], site: "" };
    },
    traceOff: (state: droneStateType, action: PayloadAction<string>) => {
      delete state.showTraceList[action.payload];
      delete state.showTracePoints[action.payload];
    },
    setTracePoints: (
      state: droneStateType,
      action: PayloadAction<{ id: string; points: number[][]; site: string }>,
    ) => {
      state.showTracePoints[action.payload.id] = { points: action.payload.points, site: action.payload.site };
    },
  },
});

export const { traceOpen, traceOff, setTracePoints } = droneState.actions;

export default droneState.reducer;
