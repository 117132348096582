import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DroneInfo {
  attacking_ttl: number;
  confirmed: boolean;
  detected_freq: number;
  direction: number;
  distance: number;
  drone_id: string;
  drone_type: string;
  image_url: string;
  name: string;
  severity: number;
  speed: number;
  can_attack: boolean;
  attacking: boolean;
  attack_type: string;
  whitelisted: boolean;
  coordinate?: Coord;
  tracing?: Tracing;
}
interface Coord {
  latitude: number;
  longitude: number;
  altitude: number;
  height: number;
}
interface Tracing {
  origin: Origin;
  points: Points[];
}
interface Origin {
  altitude: number;
  height: number;
  latitude: number;
  longitude: number;
}

interface Points {
  x: number;
  y: number;
}

interface SiteDrones {
  data: DroneInfo[];
  drone_id: string;
  sites: { id: string; is_main: boolean; direction: number }[];
}
interface payloadType {
  actionType: string;
  droneId: string;
}

const initialState: SiteDrones[] = [];

export const curDroneInfoSlice = createSlice({
  name: "curDroneInfoSlice",
  initialState,
  reducers: {
    updateDroneInfo: (state, action: PayloadAction<payloadType>) => {
      const { droneId, actionType } = action.payload;
      const drone = state.find(item => item.drone_id === droneId);
      if (drone && drone.data.length > 0) {
        if (actionType === "whitelisted") {
          drone.data[0].whitelisted = !drone.data[0].whitelisted;
        } else if (actionType === "attack") {
          drone.data[0].attacking = !drone.data[0].attacking;
        }
      }
    },
    initDroneInfo: (state: SiteDrones[], action: PayloadAction<SiteDrones[]>) => {
      if (!action.payload) {
        return;
      } else {
        try {
          state = action.payload;
          return state;
        } catch (error) {
          console.log("init drone info error", error);
        }
      }
    },
  },
});

export const { updateDroneInfo, initDroneInfo } = curDroneInfoSlice.actions;

export default curDroneInfoSlice.reducer;
